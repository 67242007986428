import i18n from '@/libs/i18n'
/**
 * Logic for create new tags
 * Will use in many component, be careful when want to change this function
 * @param {Array} tagFormArray array of tag form that you want to post to api, pass it so direclty change the id to new pushed tags id
 */
export default function useLocalization () {
  const $t = key => i18n.t(key)

  return {
    $t
  }
}